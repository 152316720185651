import { USER_STORAGE_KEYS } from './authentication.service';
import { Injectable } from '@angular/core';

import { LoginService } from '../api/login.service';
import { environment } from 'src/environments/environment';

/** Time in milliseconds after which user should be force logout */
const TIME_IDLE = 1200000; // 20 minutes
const LAST_ACTIVITY_CHECK_INTERVAL = 1000;

@Injectable({
	providedIn: 'root'
})
export class LastActivityTrackerService {
	constructor(
		private _login: LoginService,
	) {
		setInterval(() => {
			this.check();
		}, LAST_ACTIVITY_CHECK_INTERVAL);
	}

	/** Track the last activity. */
	public track() {
		localStorage.setItem(USER_STORAGE_KEYS.LAST_REQUEST_TIME, String(Date.now()))
	}

	private check() {
		const lastRequestTime = Number(localStorage.getItem(USER_STORAGE_KEYS.LAST_REQUEST_TIME));

		if(environment.schoolLocation == 'SANDBOX?'){
			return false;
		}

		if (lastRequestTime && Date.now() - lastRequestTime >= TIME_IDLE) {
			this._login.localLogOut();
		}
	}
}
